import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import Nav from 'components/Nav/Nav'
import Home from 'pages/Home'
import MassagenView from 'pages/MassagenView'
import Footer from 'components/Footer/Footer'
import { useEffect } from 'react'
import Massage from 'pages/Massage'
import BewegungView from 'pages/BewegungView'
import Impressum from 'pages/Impressum'
import Behandlungskosten from 'pages/Behandlungskosten'
import Datenschutz from 'pages/Datenschutz'
import Kontakt from 'pages/Kontakt'
import Feldenkrais from 'pages/Feldenkrais'
import Kurse from 'pages/Kurse'
import Dashboard from 'pages/Dashboard'

function App() {
  return (
    <div className="App min-h-screen h-full relative w-full flex flex-col overflow-x-hidden">
      <Nav />
      <div className="flex-1">
        <Routes>
          {/* <Route path="/"> */}
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/preise" element={<Behandlungskosten />}></Route>
          <Route path="/kurse" element={<Kurse />}></Route>
          <Route path="/feldenkrais" element={<Feldenkrais />}></Route>
          {/* <Route path="bewegung/:bewegung" element={<Massage />} /> */}
          <Route path="/bewegung" element={<BewegungView />}></Route>
          <Route path="/massagen/:massage" element={<Massage />} />
          <Route path="/massagen" element={<MassagenView />}></Route>
          <Route path="/kontakt" element={<Kontakt />}></Route>
          <Route path="/datenschutz" element={<Datenschutz />}></Route>
          <Route path="/impressum" element={<Impressum />}></Route>
          <Route path="/*" element={<Home />} />
          <Route path="/" exact element={<Home />} />
          {/* </Route> */}
        </Routes>
      </div>
      <Footer />
    </div>
  )
}

export default App
