import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import 'styles/Kontakt.scss'

import massagen from 'data/massagen.json'
import bewegung from 'data/bewegung.json'
import feldenkrais from 'data/feldenkrais.json'
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
function Kontakt(props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const [betreff, setBetreff] = useState(
    searchParams.get('betreff') || 'Massage'
  )
  const [kursInfo, setKursInfo] = useState(searchParams.get('kurs'))
  const [options, setOptions] = useState([])

  // Toast
  const [showToast, setShowToast] = useState()
  const [fadeIn, setFadeIn] = useState(false)
  const [toastError, setToastError] = useState(false)
  const [toastTitle, setToastTitle] = useState('')

  const createToast = (text, error) => {
    setToastTitle(text)
    setToastError(error)
    setShowToast(true)
    setTimeout(() => {
      setFadeIn(true)
      setTimeout(() => {
        setFadeIn(false)
        setTimeout(() => {
          setShowToast(false)
        }, 1000)
      }, 7500)
    }, 250)
  }

  useEffect(() => {
    if (searchParams.get('status') === 'success') {
      createToast('E-Mail wurde erfolgreich gesendet!')
    } else if (searchParams.get('status') === 'error') {
      createToast('E-Mail konnte nicht gesendet werden!', true)
    }
  }, [])

  useEffect(() => {
    let opts = []
    if (betreff == 'Massage') {
      opts = massagen
    } else if (betreff == 'Kurse') {
      opts = bewegung.concat(feldenkrais)
    }
    setOptions(opts)
  }, [betreff])

  return (
    <div className="py-28 px-10 w-full sm:w-2/3 ipad:w-1/2 sm:px-0 max-w-3xl mx-auto form">
      <h1 className="font-bold text-4xl mt-6">Kontakt</h1>
      <div className="mt-10">
        <p>
          Sie können uns gerne jederzeit, telefonisch unter{' '}
          <span className="font-semibold">01/812 44 71</span>, per Mail an{' '}
          <a
            href="mailto:praxis@mabefe.at"
            className="font-semibold hover:text-primary-dark transition-colors duration-200"
          >
            praxis@mabefe.at
          </a>{' '}
          oder durch unser{' '}
          <span className="font-semibold">Kontaktformular</span> unterhalb,
          erreichen.
        </p>
      </div>
      <div className="mt-10">
        <h2 className="font-semibold text-lg mb-6">Kontaktformular</h2>
        <form className="w-full" action="/scripts/sendMail.php" method="post">
          <div className="flex flex-wrap flex-col sm:flex-row sm:mb-8 sm:gap-4">
            <div className="flex-1 mb-9 md:mb-0 relative">
              <input
                required
                type="text"
                name="firstname"
                pattern="[a-zA-Z- ]{2,}"
                placeholder=" "
                className="block w-full appearance-none focus:outline-none bg-transparent pt-3 pb-1.5 px-3 leading-tight border-b transition-colors duration-300"
              />
              <label
                htmlFor="firstname"
                className="absolute top-0 uppercase tracking-wide text-gray-500 text-sm font-semibold transition-all duration-300 left-2 transform translate-y-1/2 select-none pointer-events-none"
              >
                Vorname <span className="font-semibold text-red-500">*</span>
              </label>
            </div>
            <div className="flex-1 mb-9 md:mb-0 relative">
              <input
                required
                type="text"
                name="lastname"
                pattern="[a-zA-Z- ]{2,}"
                placeholder=" "
                className="block w-full appearance-none focus:outline-none bg-transparent pt-3 pb-1.5 px-3 leading-tight border-b transition-colors duration-300"
              />
              <label
                htmlFor="lastname"
                className="absolute top-0 uppercase tracking-wide text-gray-500 text-sm font-semibold transition-all duration-300 left-2 transform translate-y-1/2 select-none pointer-events-none"
              >
                Nachname <span className="font-semibold text-red-500">*</span>
              </label>
            </div>
          </div>
          <div className="flex flex-wrap flex-col sm:flex-row sm:mb-8 sm:gap-4">
            <div className="flex-1 mb-9 md:mb-0 relative">
              <input
                required
                type="email"
                name="email"
                placeholder=" "
                className="block w-full appearance-none focus:outline-none bg-transparent pt-3 pb-1.5 px-3 leading-tight border-b transition-colors duration-300"
              />
              <label
                htmlFor="email"
                className="absolute top-0 uppercase tracking-wide text-gray-500 text-sm font-semibold transition-all duration-300 left-2 transform translate-y-1/2 select-none pointer-events-none"
              >
                E-Mail <span className="font-semibold text-red-500">*</span>
              </label>
            </div>
            <div className="flex-1 mb-9 md:mb-0 relative">
              <input
                type="tel"
                name="mobile"
                placeholder=" "
                className="block w-full appearance-none focus:outline-none bg-transparent pt-3 pb-1.5 px-3 leading-tight border-b transition-colors duration-300"
              />
              <label
                htmlFor="mobile"
                className="absolute top-0 uppercase tracking-wide text-gray-500 text-sm font-semibold transition-all duration-300 left-2 transform translate-y-1/2 select-none pointer-events-none"
              >
                Telefonnummer
              </label>
            </div>
          </div>
          <div className="flex flex-wrap flex-col sm:flex-row sm:mb-8 sm:gap-4">
            <div className="flex-1 relative mb-9 md:mb-0">
              <label
                className="absolute top-0 uppercase tracking-wide text-gray-500 text-sm font-semibold left-2 transform scale-95 -translate-y-3 -translate-x-1 select-none pointer-events-none"
                htmlFor="subject"
              >
                Betreff
              </label>
              <select
                name="betreff"
                value={betreff}
                onChange={(e) => setBetreff(e.target.value)}
                className="block w-full focus:outline-none bg-transparent pt-3
                pb-1.5 px-3 border-b cursor-pointer"
              >
                <option value="Massage">Massage</option>
                <option value="Kurse">Kurse & Termine</option>
                <option value="Sonstiges">Sonstiges</option>
              </select>
            </div>
            {betreff == 'Massage' || betreff == 'Kurse' ? (
              <div className={`flex-1 relative mb-9 md:mb-0`}>
                <label
                  className="absolute top-0 uppercase tracking-wide text-gray-500 text-sm font-semibold left-2 transform scale-95 -translate-y-3 -translate-x-1 select-none pointer-events-none"
                  htmlFor="subject"
                >
                  {betreff}
                </label>
                <select
                  className="block w-full focus:outline-none bg-transparent pt-3 pb-1.5 px-3 leading-tight border-b cursor-pointer"
                  name="betreff_info"
                >
                  {options.map((option, i) => {
                    return (
                      <option
                        key={i}
                        value={`${option.type}`}
                        selected={kursInfo == option.type}
                      >
                        {option.type}
                      </option>
                    )
                  })}
                </select>
              </div>
            ) : (
              <div className={`flex-1 relative mb-9 md:mb-0`}>
                <input
                  required={betreff == 'Sonstiges'}
                  type="text"
                  name="sonstiges"
                  placeholder=" "
                  className="block w-full focus:outline-none bg-transparent pt-2.5 pb-1.5 px-3 border-b transition-colors duration-300"
                />
                <label
                  htmlFor="sonstiges"
                  className="absolute top-0 uppercase tracking-wide text-gray-500 text-sm font-semibold transition-all duration-300 left-2 transform translate-y-1/2 select-none pointer-events-none"
                >
                  Sonstiges{' '}
                  <span className="font-semibold text-red-500">*</span>
                </label>
              </div>
            )}
          </div>
          <div className="pt-4 flex flex-wrap flex-col sm:flex-row sm:gap-4">
            <div className="flex-1 relative">
              <label
                className="absolute top-0 uppercase tracking-wide text-gray-500 text-sm font-semibold left-2 transform scale-95 -translate-y-7 -translate-x-1 select-none pointer-events-none"
                htmlFor="subject"
              >
                Nachricht <span className="font-semibold text-red-500">*</span>
              </label>
              <textarea
                required
                name="nachricht"
                rows="5"
                className="block w-full appearance-none focus:outline-none bg-transparent pt-3 pb-1.5 px-3 leading-tight border rounded-md transition-colors duration-300 resize-none"
              ></textarea>
            </div>
          </div>
          <p className="text-gray-500 mt-4">
            <span className="font-semibold text-red-500">*</span> Pflichtfelder
          </p>

          <button
            type="submit"
            className="mt-9 w-full sm:w-auto py-1 px-10 border border-primary rounded-md grid place-items-center hover:bg-primary hover:text-white transition-colors duration-300"
          >
            Senden
          </button>
        </form>
      </div>
      {showToast && (
        <div
          className={`fixed top-24 flex justify-between items-center w-60 h-10 px-2.5 sm:w-96 sm:h-12 md:px-5 border rounded-lg ${
            toastError ? 'border-red-500' : 'border-primary-dark'
          } bg-white z-50 ${
            fadeIn ? 'right-4' : ' -right-96 '
          } transition-all duration-1000 ease-in-out`}
        >
          <h1 className="text-semibold text-sm text-gray-600">
            {/* Termin erfolgreich gelöscht */}
            {toastTitle}
          </h1>
          <FontAwesomeIcon
            className={`cursor-pointer ${
              toastError ? 'text-red-500' : 'text-primary-dark'
            } opacity-75`}
            icon={toastError ? faExclamationCircle : faCheck}
            size="lg"
          />
        </div>
      )}
    </div>
  )
}

Kontakt.propTypes = {
  location: PropTypes.any,
}

export default Kontakt
